import { StateTag } from '@ballerine/common';

export const tagToBadgeData = {
  [StateTag.APPROVED]: { variant: 'success', text: 'Approved' },
  [StateTag.REVISION]: { variant: 'warning', text: 'Revisions' },
  [StateTag.REJECTED]: { variant: 'destructive', text: 'Rejected' },
  [StateTag.RESOLVED]: { variant: 'success', text: 'Resolved' },
  [StateTag.MANUAL_REVIEW]: { variant: 'info', text: 'Manual Review' },
  [StateTag.COLLECTION_FLOW]: { variant: 'violet', text: 'Collection in Progress' },
  [StateTag.PENDING_PROCESS]: { variant: 'warning', text: 'Pending ID Verification' },
  [StateTag.FAILURE]: { variant: 'destructive', text: 'Failed' },
  [StateTag.DATA_ENRICHMENT]: { variant: 'violet', text: 'Awaiting 3rd Party Data' },
  [StateTag.DISMISSED]: { variant: 'success', text: 'Dismissed' },
  [StateTag.FLAGGED]: { variant: 'destructive', text: 'Flagged' },
  ['nfc']: { variant: 'info', text: 'NFC' },
  ['back_ocr']: { variant: 'info', text: 'Back OCR' },
  ['liveness']: { variant: 'info', text: 'Liveness' },
  ['front_ocr']: { variant: 'info', text: 'Front OCR' },
  ['ica_validation']: { variant: 'info', text: 'ICA Validation' },
  ['liveness_face_compare']: { variant: 'info', text: 'Liveness Face Compare' },
  ['trade_license_uploaded']: { variant: 'info', text: 'Trade License Uploaded' },
  ['utility_bill_uploaded']: { variant: 'info', text: 'Utility Bill Uploaded' },
  ['vat_uploaded']: { variant: 'info', text: 'VAT Uploaded' },
  ['moa_uploaded']: { variant: 'info', text: 'MOA Uploaded' },
  ['aoa_uploaded']: { variant: 'info', text: 'AOA Uploaded' },
  ['share_certificate_uploaded']: { variant: 'info', text: 'Share Certificate Uploaded' },
  ['power_of_attorney_uploaded']: { variant: 'info', text: 'POA Uploaded' },
  ['ejari_uploaded']: { variant: 'info', text: 'Ejari Uploaded' },
  ['board_resolution_uploaded']: { variant: 'info', text: 'Board Resolution Uploaded' },
  ['account_opened']: { variant: 'info', text: 'Account Opened' },
} as const;
